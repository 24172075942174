<template>
  <StoryblokComponent v-if="story" :blok="story.content" />
</template>
<script setup>
const story = await useAsyncStoryblok("/home", {
  version: useRuntimeConfig().public.storyblokVersion,
});

useSeoMeta({
  title: story.value.content.metatags.title,
  description: story.value.content.metatags.description,
});
</script>
